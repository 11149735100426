import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Typography,
  ListItemIcon,
  MenuItem,
  TextField as MuiSelect,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';

const SelectFine = ({
  classes,
  items,
  value,
  label,
  textColor,
  labelColor,
  variant,
  onChange,
  fullWidth,
  icon,
  userDelivery,
  onAddDelivery,
  placeholder,
  white,
}) => {
  const { t } = useTranslation();

  function handleChange(event) {
    onChange(event.target.value);
  }

  function handleDialogOpen() {
    onAddDelivery();
  }

  return (
    <MuiSelect
      select
      fullWidth={fullWidth}
      value={value}
      label={label}
      variant={variant}
      onChange={handleChange}
      className={classes.lang}
      SelectProps={{
        IconComponent: (props) => <ExpandMoreIcon style={{ fontSize: 20 }} />,
      }}
      InputProps={{
        style: {
          fontSize: 'small',
          color: { textColor },
          fontWeight: 500,
          placeholder: placeholder,
        },
      }} // font size of input text
      InputLabelProps={{
        style: {
          fontSize: 'medium',
          color: { labelColor },
        },
      }} // font size of label text
    >
      {userDelivery && (
        <MenuItem onClick={handleDialogOpen}>
          <Box className={classes.langContainer}>
            <ListItemIcon className={classes.iconContainer}>
              <AddBoxIcon
                className={classes.icon}
                style={{ color: '#000000' }}
              />
            </ListItemIcon>
            <Typography variant="body1" className={classes.iconText}>
              | {t('add_delivery_method')}
            </Typography>
          </Box>
        </MenuItem>
      )}

      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Box className={classes.langContainer}>
            <ListItemIcon className={classes.iconContainer}>
              {icon && <img src={icon} className={classes.icon} />}
            </ListItemIcon>
            <Typography variant="body1" className={classes.iconText}>
              {icon && '|'} {item.title}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

const styles = (theme) => ({
  input: {
    backgroundColor: '#fff',
  },
  langContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  lang: {
    backgroundColor: (props) => (props.white ? '#fff' : '#F2F2F7'),
    borderRadius: '10px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:after': {
      borderBottomColor: '#fff',
    },
    '&:hover': {
      borderBottom: 'none',
      backgroundColor: (props) => (props.white ? '#f5f5f5' : '#b6b8ba'),
    },
  },
  iconText: {
    lineHeight: '20px',
  },
  iconContainer: {
    minWidth: '15px',
  },
  icon: {
    height: '14px',
    width: '14px',
    marginTop: '3px',
    marginRight: '5px',
    marginLeft: '10px',
  },
});

export default withStyles(styles)(SelectFine);
