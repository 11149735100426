import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableContainer,
  Paper,
  Tooltip,
} from '@material-ui/core';
import QuantityField from './QuantityField';
import StringField from './StringField';

const RowCheckbox = ({ id, checked, onChange, className }) => {
  function handleOnChange() {
    onChange && onChange(id);
  }

  return (
    <Checkbox
      className={className}
      disableRipple
      checked={checked}
      onChange={handleOnChange}
    />
  );
};

const BasketTable = ({
  classes,
  data,
  onQuantityChange,
  onReferenceChange,
  onSelect,
  onSelectAll,
  onWaitChange,
}) => {
  const { t } = useTranslation();

  function handleQuantityChange(id, quantity) {
    onQuantityChange && onQuantityChange(id, quantity);
  }

  function handleWaitChange(id, value) {
    onWaitChange && onWaitChange(id, value);
  }

  function handleReferenceChange(id, reference) {
    onReferenceChange && onReferenceChange(id, reference);
  }

  function handleSelectAll(event) {
    onSelectAll && onSelectAll(event.target.checked);
  }

  const currency = data.length ? data[0].currency : '';
  const confirmedData = useMemo(
    () => data.filter((value) => value.confirmed),
    [data]
  );
  const isAllConfirmed = confirmedData.length === data.length;
  return (
    <div className={`${classes.container}`}>
      <TableContainer
        component={Paper}
        variant="rounded"
        className={classes.tableContainer}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  className={classes.checkbox}
                  disableRipple
                  checked={isAllConfirmed}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>{t('brand')}</TableCell>
              <TableCell>{t('part_number')}</TableCell>
              <TableCell>{t('price_with_currency', { currency })}</TableCell>
              <TableCell>{t('quantity')}</TableCell>
              <TableCell>{t('wait')}</TableCell>
              <TableCell>{t('total_with_currency', { currency })}</TableCell>
              <TableCell>{t('weight_kg')}</TableCell>
              <TableCell>{t('booking')}</TableCell>
              <TableCell>{t('delivery')}</TableCell>
              <TableCell>{t('reference')}</TableCell>
              <TableCell>
                <div>{t('description')}</div>
                <div>{t('comment')}</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((value, index) => (
              <TableRow
                key={value.id}
                className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
              >
                <TableCell padding="checkbox">
                  <RowCheckbox
                    id={value.id}
                    className={classes.checkbox}
                    checked={value.confirmed}
                    onChange={onSelect}
                  />
                </TableCell>
                <TableCell>{value.brand}</TableCell>
                <TableCell>{value.partNumber}</TableCell>
                <TableCell>{value.price}</TableCell>
                <TableCell>
                  <QuantityField
                    className={classes.quantityField}
                    id={value.id}
                    quantity={value.quantity}
                    onChange={handleQuantityChange}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={t('wait_tooltip')}>
                    <Checkbox
                      color="warning"
                      size="small"
                      checked={!!value.wait}
                      onChange={(e, val) => handleWaitChange(value.id, val)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>{value.amount}</TableCell>
                <TableCell>{value.weightKg}</TableCell>
                <TableCell>{value.booking}</TableCell>
                <TableCell>
                  {value.delivery} ({value.tariff})
                </TableCell>
                <TableCell>
                  <StringField
                    className={classes.stringField}
                    id={value.id}
                    value={value.yourReference}
                    onChange={handleReferenceChange}
                  />
                </TableCell>
                <TableCell>
                  <div>{value.description}</div>
                  <div className={classes.warnValue}>
                    {t(value.errorComment)}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const styles = (theme) => ({
  table: {
    borderRadius: '10px',
    border: 'none',
  },
  container: {
    flex: '1 0 0px',
  },
  tableContainer: {
    margin: '0 auto',
    width: '90%',
    boxShadow: 'none',
    border: '1px solid #00000040',
    borderRadius: '10px',
  },
  evenRow: {
    backgroundColor: '#FBFBFB',
    borderTop: '1px solid #00000040',
  },
  oddRow: {
    backgroundColor: '#ffffff',
    borderTop: '1px solid #00000040',
  },
  quantityField: {
    alignItems: 'center',
  },
  stringField: {
    alignItems: 'center',
    root: {
      textAlign: 'center',
    },
  },
  checkbox: {
    padding: 0,
  },
  warnValue: {
    color: 'red',
  },
});

export default withStyles(styles)(BasketTable);
