import React, { useState, useEffect } from 'react';
import { statesList } from '../api/order-api';
import { withStyles, Tabs, Tab, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import SearchInput from './SearchInput';
import SelectFine from './SelectFine';
import { useTranslation } from 'react-i18next';
const SearchInputWithSelect = ({
  classes,
  className,
  selectValue,
  searchValue,
  onSelectChange,
  onSearchChange,
  onSearchClick,
}) => {
  const [states, setStates] = useState([]);

  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    onSelectChange(newValue);
  };

  useEffect(() => {
    const fetchStatesList = async () => {
      const fetchStatesList = await statesList();
      setStates(fetchStatesList);
    };
    fetchStatesList();
  }, []);

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.colContainer}>
        <Typography variant="h5" className={classes.searchHeading}>
          {t('choose_search')}
        </Typography>
        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
          value={Number(selectValue)}
          centered
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              marginLeft: selectValue === 0 ? '2px' : '-2px',
            },
          }}
        >
          <Tab
            disableRipple
            label={<StyledTypography>{t('by_part_number')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
            style={{
              borderRadius: '10px 0 0 10px',
            }}
          />

          <Tab
            disableRipple
            label={<StyledTypography>{t('order_number')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
          />
          <Tab
            disableRipple
            label={<StyledTypography>{t('reference')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
          />
          <Tab
            disableRipple
            label={<StyledTypography>{t('state')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
            style={{
              borderRadius: '0 10px 10px 0',
            }}
          />
        </Tabs>
      </div>

      <div className={classes.colContainer}>
        <Typography variant="h5" className={classes.searchHeading}>
          {selectValue === 0 ? t('provide_part_number') : ''}
          {selectValue === 1 ? t('provide_order_number') : ''}
          {selectValue === 2 ? t('provider_reference') : ''}
          {selectValue === 3 ? t('provide_state') : ''}
        </Typography>
        {selectValue < 3 ? (
          <SearchInput
            className={classes.searchInput}
            value={searchValue}
            onChange={onSearchChange}
            onSearch={onSearchClick}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              minHeight: 32,
            }}
          >
            <SelectFine
              fullWidth
              white
              placeholder={t('choose_state')}
              items={states.map((item) => ({
                value: item.id,
                title: t(`order_status.${item.shortName}`),
              }))}
              value={searchValue}
              onChange={(value) => {
                onSearchChange(value.toString());
                onSearchClick({ stateId: value.toString() });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const StyledTypography = withStyles((theme) => ({
  root: {
    color: '#000',
    zIndex: 1000,
    fontWeight: 500,
    fontSize: '12px',
    ...theme.typography,
  },
}))(Typography);

const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
    },
    select: {
      marginRight: theme.spacing(1),
      minWidth: '125px',
    },
    searchInput: {
      width: 'initial',
    },
    tabs: {
      minWidth: '270px',
      margin: '0 auto',
      minHeight: '32px',
    },
    tabRoot: {
      backgroundColor: '#7676801F',
      opacity: 1,
      minHeight: '32px',
      [theme.breakpoints.up('sm')]: {
        minWidth: '100px',
        height: '32px',
      },
    },
    indicator: {
      marginBottom: '2px',
      zIndex: '0',
      height: '88%',
      borderRadius: '10px',
      backgroundColor: '#fff',
      transition: 'left 0.3s',
      boxShadow:
        '0px 2.25px 6px rgba(0, 0, 0, 0.12), 0px 2.25px 0.75px rgba(0, 0, 0, 0.04)',
    },
    searchHeading: {
      maxWidth: '100%',
      textAlign: 'left',
      fontWeight: '500',
      fontSize: '18px',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    colContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '30px',
      },
    },
    button: {
      textTransform: 'none',
      fontSize: '12px',
      minWidth: '70px',
      height: '32px',
      background: 'rgba(0, 122, 255, 0.15)',
      borderRadius: '8px',
      color: '#007AFF',
    },
  };
};

export default withStyles(styles)(SearchInputWithSelect);
